import React from 'react';
import { useResponsive } from '../../../../hooks/useResponsive';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import * as S from './OIChangeTable.styles';


export const OIChangeTable = ({ activeItem, setActiveItem, data, type }) => {
  const { isTablet, isDesktop } = useResponsive();

  const results = data.map((item, index) =>
  ({
    key: index,
    symbol: item.symbol,
    strike: item.strike,
    expiryDate: item.expDate,
    periodType: item.periodType,
    prevOi: item.prevOi,
    currOi: item.currOi,
    oiChange: item.oiChange,
    oiChangePct: item.oiChangePct
  }))

  const columns = [
    {
      title: 'SYM',
      dataIndex: 'symbol',
      width: '15%',
      render: (symbol, { key }) => <S.Text $isActive={activeItem.key === key}>{symbol}</S.Text>,
    },
    {
      title: 'STR',
      dataIndex: 'strike',
      width: '10%',
      render: (strike, { key }) => <S.Text $isActive={activeItem.key === key}>{strike}</S.Text>,
    },

    {
      title: 'EXP',
      dataIndex: 'expiryDate',
      width: '10%',
      render: (expiryDate, { key }) => {
        const [year, month, day] = expiryDate.split('-');

        return <S.Text $isActive={activeItem.key === key}>
          {year}<br />{month}-{day}
        </S.Text>
      },
    },
    {
      title: 'PER',
      dataIndex: 'periodType',
      width: '10%',
      render: (periodType, { key }) => <S.Text $isActive={activeItem.key === key}>{periodType}</S.Text>,
    },
    {
      title: 'POI',
      dataIndex: 'prevOi',
      render: (prevOi, { key }) => <S.Text $isActive={activeItem.key === key}>{prevOi}</S.Text>,
    },
    {
      title: 'COI',
      dataIndex: 'currOi',
      render: (currOi, { key }) => <S.Text $isActive={activeItem.key === key}>{currOi}</S.Text>,
    },
    {
      title: 'CHG',
      dataIndex: 'oiChange',
      render: (oiChange, { key }) => {
        // 根据 type 的值设置颜色
        const color = type === 'Call INCR' || type === 'Put INCR' ? 'green' : 'red';
        return <S.Text $isActive={activeItem.key === key} style={{ color }}>{oiChange}</S.Text>;
      },
    },
    {
      title: 'PCT',
      dataIndex: 'oiChangePct',
      width: '20%',
      render: (oiChangePct, { key }) => {
        // 根据 type 的值设置颜色
        const color = type === 'Call INCR' || type === 'Put INCR' ? 'green' : 'red';
        return <S.Text $isActive={activeItem.key === key} style={{ color }}>{oiChangePct}</S.Text>;
      },
    }

  ];
  return (
    <S.Table
      size={'small'}
      pagination={false}
      columns={columns}
      dataSource={results}
      scroll={{ y: isDesktop ? 600 : isTablet ? 620 : 400 }}
      onRow={(record) => {
        return {
          onClick: () => setActiveItem(record),
        };
      }}
    />
  );
};
