import React, { useState, useEffect } from 'react';
import { useResponsive } from '../../../hooks/useResponsive';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { results } from '../../../constants/bloodTestResults';
import { OIChangeTable } from './oiChangeTable/OIChangeTable';

import * as S from '../../common/base/BaseTabs/BaseTabs.style';
import styled from 'styled-components';

// 创建一个透明的 Tabs 容器
const TransparentTabs = styled.div`
  background-color: transparent;  // 设置透明背景
  overflow: hidden;  // 确保圆角效果
`;

export const OIChangeTabs = () => {
    const { mobileOnly } = useResponsive();
    const data = useAppSelector((state) => state.option.oiChanges.list);
    const [filterdData, setFilteredData] = useState([]);

    useEffect(() => {
        // 默认选项卡的初始化数据
        if (data.length > 0) setFilteredData(data.filter(item => item.category === 'top positive call OI change'));    
         
    }, [data]);



    const onChange = (key) => {
        let updatedData = [];
        switch (key) {
            case '1':
                updatedData = data.filter(item => item.category === 'top positive call OI change');
                break;
            case '2':
                updatedData = data.filter(item => item.category === 'top negative call OI change');
                break;
            case '3':
                updatedData = data.filter(item => item.category === 'top positive put OI change');
                break;
            case '4':
                updatedData = data.filter(item => item.category === 'top negative put OI change');
                break;
            default:
                updatedData = []; // 默认情况下设置为空数组
        }
        // 只有在 updatedData 不为空时才更新 filterdData
        if (updatedData.length > 0) {
            setFilteredData(updatedData);
        }
    };

    const [activeItem, setActiveItem] = useState({}); // 初始状态可以是空对象或其他合适的初始值

    const items = [
        {
            key: '1',
            label: 'Call INCR',
            children: filterdData.length > 0 ? (
                <OIChangeTable activeItem={activeItem} setActiveItem={setActiveItem} data={filterdData} type='Call INCR' />
            ) : (
                <div></div>
            ),
        },
        {
            key: '2',
            label: 'Call DCR',
            children: filterdData.length > 0 ? (
                <OIChangeTable activeItem={activeItem} setActiveItem={setActiveItem} data={filterdData} type='Call DCR' />
            ) : (
                <div></div>
            ),
        },
        {
            key: '3',
            label: 'Put INCR',
            children: filterdData.length > 0 ? (
                <OIChangeTable activeItem={activeItem} setActiveItem={setActiveItem} data={filterdData} type='Put INCR' />
            ) : (
                <div></div>
            ),
        },
        {
            key: '4',
            label: 'Put DCR',
            children: filterdData.length > 0 ? (
                <OIChangeTable activeItem={activeItem} setActiveItem={setActiveItem} data={filterdData} type='Put DCR' />
            ) : (
                <div></div>
            ),
        },
    ];

    return (
        <S.Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    );
}